import {
  ApolloError,
  gql,
  useApolloClient,
  useMutation,
  useQuery,
} from "@apollo/client";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Button } from "../../components/button";
import { FormError } from "../../components/form-error";
import { MY_RESTAURANTS_QUERY } from "./my-restaurants";
import { useNavigate } from "react-router-dom";
import {
  CategoryOptionsQuery,
  CategoryOptionsQueryVariables,
  CreateRestaurantMutation,
  CreateRestaurantMutationVariables,
  CreateRestaurantOutput,
  MyRestaurantsQuery,
} from "../../gql/graphql";

const CREATE_RESTAURANT_MUTATION = gql`
  mutation createRestaurant($input: CreateRestaurantInput!) {
    createRestaurant(input: $input) {
      error
      ok
      restaurantId
    }
  }
`;

export const CATEGORY_OPTIONS_QUERY = gql`
  query categoryOptions {
    allCategories {
      ok
      error
      categories {
        name
      }
    }
  }
`;

interface IFormProps {
  name: string;
  address: string;
  categoryName: string;
  file: FileList;
}

export const AddRestaurant = () => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState("");

  const { data } = useQuery<
    CategoryOptionsQuery,
    CategoryOptionsQueryVariables
  >(CATEGORY_OPTIONS_QUERY);

  const categories = data?.allCategories.categories;

  const onCompleted = (
    data: { createRestaurant: CreateRestaurantOutput } | undefined
  ) => {
    // Mutation completes but when there is no data
    // data = undefined;
    if (!data || !data.createRestaurant) {
      alert("An unexpected error occurred. Please try again.");
      navigate("/", { replace: true });
      return;
    }
    const { ok, restaurantId } = data.createRestaurant;
    if (ok) {
      const { name, categoryName, address } = getValues();
      setUploading(false);

      const queryResult = client.readQuery<MyRestaurantsQuery>({
        query: MY_RESTAURANTS_QUERY,
      });

      if (queryResult) {
        client.writeQuery<MyRestaurantsQuery>({
          query: MY_RESTAURANTS_QUERY,
          data: {
            myRestaurants: {
              ...queryResult.myRestaurants,
              restaurants: [
                ...(queryResult.myRestaurants.restaurants || []),
                {
                  __typename: "Restaurant",
                  id: restaurantId,
                  name,
                  coverImg: imageUrl,
                  address,
                  category: {
                    __typename: "Category",
                    name: categoryName,
                  },
                },
              ],
            },
          },
        });
      }
      navigate("/", { replace: true });
    }
  };

  const onError = (error: any) => {
    console.error("Mutation error:", error);
    alert("Failed to create restaurant. Please try again.");
    setUploading(false);
    navigate("/", { replace: true });
  };

  const [createRestaurantMutation] = useMutation<
    CreateRestaurantMutation,
    CreateRestaurantMutationVariables
  >(CREATE_RESTAURANT_MUTATION, {
    onCompleted,
    onError,
  });
  const { register, getValues, formState, handleSubmit } = useForm<IFormProps>({
    mode: "onChange",
  });
  const [uploading, setUploading] = useState(false);
  const onSubmit = async () => {
    try {
      setUploading(true);
      const { file, name, categoryName, address } = getValues();
      const actualFile = file[0];
      const formBody = new FormData();
      formBody.append("file", actualFile);

      const response = await fetch("http://localhost:4000/uploads", {
        method: "POST",
        body: formBody,
      });
      if (!response.ok) {
        throw new Error(`Failed to upload image: ${response.statusText}`);
      }
      const { url: coverImg } = await response.json();

      setImageUrl(coverImg);

      createRestaurantMutation({
        variables: {
          input: {
            name,
            categoryName,
            address,
            coverImg,
          },
        },
      });
    } catch (error) {
      alert(error);
      navigate("/", { replace: true });
    }
  };

  return (
    <div className="container flex flex-col items-center mt-52">
      <Helmet>
        <title>Add Restaurant | Nuber Eats</title>
      </Helmet>
      <h4 className="font-semibold text-2xl mb-3">Add Restaurant</h4>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid max-w-screen-sm gap-3 mt-5 w-full mb-5"
      >
        <input
          {...register("name", {
            required: "Name is required",
            minLength: {
              value: 5,
              message: "Name must be at least 5 characters long.",
            },
          })}
          className="input"
          type="text"
          name="name"
          placeholder="Name"
        />
        {formState?.errors?.name && (
          <p className="font-medium text-red-500">
            {formState.errors.name.message}
          </p>
        )}
        <input
          {...register("address", { required: "Address is required." })}
          className="input"
          type="text"
          name="address"
          placeholder="Address"
        />
        {formState?.errors?.address && (
          <p className="font-medium text-red-500">
            {formState.errors.address.message}
          </p>
        )}
        <select
          {...register("categoryName", {
            required: "Category Name is required",
          })}
          className="input"
          name="categoryName"
          defaultValue=""
        >
          <option value="" disabled hidden>
            Select a Category
          </option>
          {categories?.map((category: any, index: any) => (
            <option key={index} value={category.name}>
              {category.name}
            </option>
          ))}
        </select>

        <input
          {...register("file", { required: "Image file is required" })}
          type="file"
          name="file"
          accept="image/*"
        />
        {formState?.errors?.file && (
          <p className="font-medium text-red-500">
            {formState.errors.file.message}
          </p>
        )}
        <Button
          loading={uploading}
          canClick={formState.isValid}
          actionText="Create Restaurant"
        />
      </form>
      <button
        onClick={() => navigate(-1)}
        className="w-5/12 text-lg font-medium focus:outline-none text-white py-4  transition-colors bg-gray-300 hover:bg-gray-700"
      >
        Cancel
      </button>
    </div>
  );
};

import { gql, useQuery, useSubscription } from "@apollo/client";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate, useParams } from "react-router-dom";
// import {
//   MyRestaurantQuery,
//   MyRestaurantQueryVariables,
// } from "../../gql/graphql";
import DropdownButton from "../../components/action-dropdown";
import {
  MyRestaurantQuery,
  MyRestaurantQueryVariables,
  PendingOrdersSubscription,
  PendingOrdersSubscriptionVariables,
} from "../../gql/graphql";

export const MY_RESTAURANT_QUERY = gql`
  query myRestaurant($input: MyRestaurantInput!) {
    myRestaurant(input: $input) {
      ok
      error
      restaurant {
        id
        name
        coverImg
        category {
          name
        }
        address
        menu {
          id
          name
          price
          photo
          description
          options {
            name
            extra
            choices {
              name
              extra
            }
          }
        }
      }
    }
  }
`;

const PENDING_ORDERS_SUBSCRIPTION = gql`
  subscription pendingOrders {
    pendingOrders {
      id
      status
      total
      driver {
        email
      }
      customer {
        email
      }
      restaurant {
        name
      }
    }
  }
`;

export const MyRestaurant = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  if (!id) {
    return null;
  }

  const { data } = useQuery<MyRestaurantQuery, MyRestaurantQueryVariables>(
    MY_RESTAURANT_QUERY,
    {
      variables: {
        input: {
          id: +id,
        },
      },
    }
  );

  const { data: subscriptionData } = useSubscription<
    PendingOrdersSubscription,
    PendingOrdersSubscriptionVariables
  >(PENDING_ORDERS_SUBSCRIPTION);

  useEffect(() => {
    if (subscriptionData?.pendingOrders.id) {
      navigate(`/orders/${subscriptionData.pendingOrders.id}`);
    }
  }, [subscriptionData]);

  const restaurant = data?.myRestaurant.restaurant;

  return (
    <div>
      <Helmet>
        <title>{restaurant?.name || ""} | Nuber Eats</title>
      </Helmet>
      <div
        className="  bg-gray-800 py-48 bg-center bg-cover"
        style={{
          backgroundImage: `url(${restaurant?.coverImg})`,
        }}
      >
        <div className="bg-white w-3/12 py-8 pl-48">
          <h4 className="text-4xl mb-3">{restaurant?.name}</h4>
          <h5 className="text-sm font-light mb-2">
            {restaurant?.category?.name}
          </h5>
          <h6 className="text-sm font-light">{restaurant?.address}</h6>
        </div>
      </div>
      <div className="container mt-10">
        <DropdownButton id={id} />
        <div className="ml-8 mt-10">
          {restaurant?.menu.length === 0 ? (
            <h4 className="text-xl mb-5">Please upload a dish!</h4>
          ) : (
            <div className="grid mt-16 md:grid-cols-3 gap-x-5 gap-y-10">
              {restaurant?.menu.map((dish) => (
                <Link
                  to={`/restaurant/${id}/dish/${dish.id}`}
                  className="flex justify-between items-center px-8 py-4 border-2 border-gray-300 cursor-pointer  transition-all text-black"
                >
                  <div>
                    <div className="mb-5">
                      <h3 className="text-lg font-medium flex items-center ">
                        {dish.name}{" "}
                      </h3>
                      <h4 className="font-medium">{dish.description}</h4>
                    </div>
                    <span>${dish.price}</span>
                    <div>
                      <h5 className="mt-8 mb-3 font-medium">Dish Options:</h5>
                      <div className="grid gap-2 justify-start ">
                        {dish.options?.map((option: any) => (
                          <div>
                            <span className="mr-2">{option.name}</span>
                            <span className="text-sm opacity-75">
                              (${option.extra})
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="w-32 h-32 ml-6 flex-shrink-0">
                    <img
                      className="w-full h-full object-cover rounded"
                      src={dish.photo ?? ""}
                    />
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import { gql, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import { Dish } from "../../components/dish";
import { RESTAURANT_FRAGMENT } from "../../fragments";
import { ClientDishQuery, ClientDishQueryVariables } from "../../gql/graphql";

// const DISH_QUERY = gql`
//   query dishPage($dishInput: DishInput!, $restaurantInput: RestaurantInput!) {
//     dish(input: $dishInput) {
//       ok
//       error
//       dish {
//         id
//         price
//         photo
//         description
//         options {
//           extra
//         }
//       }
//     }
//     restaurant(input: $restaurantInput) {
//       ok
//       error
//       restaurant {
//         id
//         coverImg
//       }
//     }
//   }
// `;

const CLIENT_DISH_QUERY = gql`
  query clientDish($dishInput: DishInput!, $restaurantInput: RestaurantInput!) {
    dish(input: $dishInput) {
      ok
      error
      dish {
        id
        name
        price
        photo
        description
        options {
          extra
        }
      }
    }
    restaurant(input: $restaurantInput) {
      ok
      error
      restaurant {
        id
        name
        coverImg
        category {
          name
        }
        address
        menu {
          id
          name
          price
          photo
          description
          options {
            name
            extra
          }
        }
      }
    }
  }
`;

export const ClientDishPage = () => {
  const { restaurantId, dishId } = useParams();

  if (!restaurantId) {
    return <p>Error: Restaurant ID is missing</p>;
  }

  if (!dishId) {
    return <p>Error: Dish ID is missing</p>;
  }

  const { data } = useQuery<ClientDishQuery, ClientDishQueryVariables>(
    CLIENT_DISH_QUERY,
    {
      variables: {
        dishInput: {
          dishId: +dishId,
        },
        restaurantInput: {
          restaurantId: +restaurantId,
        },
      },
    }
  );

  const dish = data?.dish?.dish;
  const restaurant = data?.restaurant?.restaurant;

  if (!dish?.options) {
    return null;
  }

  return (
    <div>
      <Helmet>
        <title>{restaurant?.name} | Nuber Eats</title>
      </Helmet>
      <div
        className="  bg-gray-700  py-28 bg-center bg-cover"
        style={{
          backgroundImage: `url(${restaurant?.coverImg})`,
        }}
      ></div>

      <div className="container mt-10">
        <h2 className="text-4xl font-medium mb-10">{restaurant?.name}</h2>
        <Link
          to={`/restaurant/${restaurantId}`}
          className="mr-8 text-white bg-gray-700 py-3 px-10"
        >
          Go Back To Dishes &rarr;
        </Link>

        <div className="mt-10 mb-10 p-6 bg-white shadow-lg rounded-lg max-w-md mx-auto">
          <img
            src={dish?.photo || ""}
            alt={dish?.name}
            className="w-full h-64 object-cover rounded-lg shadow-md mb-4"
          />
          <div className="text-center">
            <h3 className="text-4xl font-bold text-gray-800 mb-2">
              {dish?.name}
            </h3>
            <p className="text-gray-600 text-lg mb-4">{dish?.description}</p>
            <p className="text-2xl font-semibold text-gray-800">
              ${dish?.price}
            </p>
          </div>
          <div className="mt-8">
            <h4 className="text-xl font-medium text-gray-800 mb-4">Options</h4>
            {dish?.options.length > 0 ? (
              <div className="space-y-4">
                {dish?.options.map((option: any) => (
                  <div key={option.name} className="p-4 bg-gray-100 rounded-lg">
                    <h5 className="text-lg font-semibold text-gray-700">
                      {option.name}
                    </h5>
                    {option.choices && option.choices.length > 0 && (
                      <ul className="ml-4 mt-2 list-disc list-inside">
                        {option.choices.map((choice: any) => (
                          <li key={choice.name} className="text-gray-600">
                            {choice.name} (+${choice.extra})
                          </li>
                        ))}
                      </ul>
                    )}
                    {option.extra && (
                      <p className="mt-2 text-gray-600">
                        Extra: +${option.extra}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-600">
                No options available for this dish.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

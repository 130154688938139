import { gql, useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Button } from "../../components/button";
import { FormError } from "../../components/form-error";
import { MY_RESTAURANTS_QUERY } from "./my-restaurants";
import { useNavigate, useParams } from "react-router-dom";
import { DISH_QUERY } from "./dish";
import {
  DeleteDishMutation,
  DeleteDishMutationVariables,
  DishQueryVariables,
} from "../../gql/graphql";

const DELETE_DISH_MUTATION = gql`
  mutation deleteDish($input: DeleteDishInput!) {
    deleteDish(input: $input) {
      error
      ok
    }
  }
`;

interface IFormProps {
  confirmation: string;
}

export const DeleteDish = () => {
  const navigate = useNavigate();
  const { restaurantId, dishId } = useParams<{
    restaurantId: string;
    dishId: string;
  }>();
  const [deleting, setDeleting] = useState(false);

  if (!restaurantId || !dishId) {
    return null;
  }
  // const client = useApolloClient();
  const [deleteDishMutation] = useMutation<
    DeleteDishMutation,
    DeleteDishMutationVariables
  >(DELETE_DISH_MUTATION, {
    refetchQueries: [
      {
        query: DISH_QUERY,
        variables: {
          dishInput: {
            dishId: +dishId,
          },
          myRestaurantInput: {
            id: +restaurantId,
          },
        } as DishQueryVariables,
      },
    ],
    onCompleted: () => {
      navigate(`/restaurant/${restaurantId}`);
    },
    onError: (error) => {
      console.error("Mutation error:", error);
      setDeleting(false);
      alert("Failed to delete restaurant. Please try again.");
      navigate("/", { replace: true });
    },
  });
  const { register, formState, handleSubmit } = useForm<IFormProps>({
    mode: "onChange",
  });
  const onSubmit = async () => {
    setDeleting(true);
    deleteDishMutation({
      variables: {
        input: {
          dishId: +dishId,
        },
      },
    });
  };
  return (
    <div className="container flex flex-col items-center mt-52">
      <Helmet>
        <title>Delete Dish | Nuber Eats</title>
      </Helmet>
      <h4 className="font-semibold text-2xl mb-3">Delete Dish</h4>
      <h4 className="text-xl mb-3">
        To confirm deletion, type permanently delete in the text input field.
      </h4>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid max-w-screen-sm gap-3 mt-5 w-full mb-5"
      >
        <input
          {...register("confirmation", {
            required: true,
            validate: (value) =>
              value === "permanently delete" ||
              "You must type 'permanently delete' to confirm",
          })}
          className="input"
          type="text"
          name="confirmation"
          placeholder="permanently delete"
        />
        {formState?.errors?.confirmation && (
          <span className="font-medium text-red-500">
            {formState?.errors?.confirmation.message}
          </span>
        )}
        <Button
          loading={deleting}
          canClick={formState.isValid}
          actionText="Delete Restaurant"
        />
      </form>
      <button
        onClick={() => navigate(-1)}
        className="w-5/12 text-lg font-medium focus:outline-none text-white py-4  transition-colors bg-gray-300 hover:bg-gray-700"
      >
        Cancel
      </button>
    </div>
  );
};

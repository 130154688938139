import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Dish } from "../../components/dish";
import {
  CreateOrderItemInput,
  CreateOrderMutation,
  CreateOrderMutationVariables,
  RestaurantQuery,
  RestaurantQueryVariables,
} from "../../gql/graphql";
import { DishOption } from "../../components/dish-option";

const RESTAURANT_QUERY = gql`
  query restaurant($input: RestaurantInput!) {
    restaurant(input: $input) {
      ok
      error
      restaurant {
        id
        name
        coverImg
        address
        category {
          name
        }
        menu {
          id
          name
          price
          description
          photo
          options {
            name
            extra
          }
        }
      }
    }
  }
`;

const CREATE_ORDER_MUTATION = gql`
  mutation createOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      ok
      error
      orderId
    }
  }
`;

export const Restaurant = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  if (!id) {
    return <div>Error: Restaurant ID is missing</div>;
  }

  const { data } = useQuery<RestaurantQuery, RestaurantQueryVariables>(
    RESTAURANT_QUERY,
    {
      variables: {
        input: {
          restaurantId: +id,
        },
      },
    }
  );

  const [orderStarted, setOrderStarted] = useState(false);
  const [orderItems, setOrderItems] = useState<CreateOrderItemInput[]>([]);

  const getItem = (dishId: number) => {
    const result = orderItems.find((order) => order.dishId === dishId);
    return result;
  };
  const isSelected = (dishId: number) => {
    return Boolean(getItem(dishId));
  };
  const addItemToOrder = (dishId: number) => {
    if (isSelected(dishId)) {
      return;
    }
    setOrderItems((current) => [{ dishId, options: [] }, ...current]);
  };
  const removeFromOrder = (dishId: number) => {
    setOrderItems((current) =>
      current.filter((dish) => dish.dishId !== dishId)
    );
  };
  const addOptionToItem = (dishId: number, optionName: string) => {
    if (!isSelected(dishId)) {
      return;
    }
    const oldItem = getItem(dishId);
    if (oldItem) {
      const hasOption = Boolean(
        oldItem.options?.find((aOption) => aOption.name == optionName)
      );
      if (!hasOption) {
        removeFromOrder(dishId);
        setOrderItems((current) => [
          { dishId, options: [{ name: optionName }, ...oldItem.options!] },
          ...current,
        ]);
      }
    }
  };
  const removeOptionFromItem = (dishId: number, optionName: string) => {
    if (!isSelected(dishId)) {
      return;
    }
    const oldItem = getItem(dishId);
    if (oldItem) {
      removeFromOrder(dishId);
      setOrderItems((current) => [
        {
          dishId,
          options: oldItem.options?.filter(
            (option) => option.name !== optionName
          ),
        },
        ...current,
      ]);
      return;
    }
  };
  const getOptionFromItem = (
    item: CreateOrderItemInput,
    optionName: string
  ) => {
    return item.options?.find((option) => option.name === optionName);
  };

  const isOptionSelected = (dishId: number, optionName: string) => {
    const item = getItem(dishId);
    if (item) {
      return Boolean(getOptionFromItem(item, optionName));
    }
    return false;
  };

  const [createOrderMutation, { loading: placingOrder }] = useMutation<
    CreateOrderMutation,
    CreateOrderMutationVariables
  >(CREATE_ORDER_MUTATION, {
    onCompleted: (data: CreateOrderMutation) => {
      const { ok, orderId } = data.createOrder;
      if (ok) {
        // history.push(`/orders/${orderId}`);
        navigate(`/orders/${orderId}`);
      }
    },
  });

  const triggerStartOrder = () => {
    setOrderStarted(true);
  };

  const triggerCancelOrder = () => {
    setOrderStarted(false);
    setOrderItems([]);
  };

  const triggerConfirmOrder = () => {
    if (placingOrder) {
      return;
    }
    if (orderItems.length === 0) {
      alert("Can't place empty order");
      return;
    }
    const ok = window.confirm("You are about to place an order");
    if (ok) {
      createOrderMutation({
        variables: {
          input: {
            restaurantId: +id,
            items: orderItems,
          },
        },
      });
    }
  };

  const restaurant = data?.restaurant.restaurant;

  useEffect(() => {
    console.log(orderItems);
  }, [orderItems]);

  return (
    <div>
      <Helmet>
        <title>{restaurant?.name || ""} | Nuber Eats</title>
      </Helmet>
      <div
        className=" bg-gray-800 bg-center bg-cover py-48"
        style={{
          backgroundImage: `url(${restaurant?.coverImg})`,
        }}
      >
        <div className="bg-white w-3/12 py-8 pl-48">
          <h4 className="text-4xl mb-3">{restaurant?.name}</h4>
          <h5 className="text-sm font-light mb-2">
            {restaurant?.category?.name}
          </h5>
          <h6 className="text-sm font-light">{restaurant?.address}</h6>
        </div>
      </div>
      <div className="mt-8 flex justify-between items-center">
        <Link to={`/`} className="ml-8 text-white bg-gray-700 py-3 px-10">
          Go Back To Restaurants &rarr;
        </Link>
        <div className="flex items-center space-x-3">
          {!orderStarted && (
            <button
              onClick={triggerStartOrder}
              className="mr-8 text-white bg-gray-700 py-3 px-10 hover:bg-gray-900"
            >
              Start Order
            </button>
          )}
          {orderStarted && (
            <div className="flex items-center">
              <button
                onClick={triggerConfirmOrder}
                className="mr-8 text-white bg-gray-700 py-3 px-10 hover:bg-gray-900"
              >
                Confirm Order
              </button>
              <button
                onClick={triggerCancelOrder}
                className="mr-8 text-white bg-red-500 py-3 px-10 hover:bg-red-800"
              >
                Cancel Order
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="mt-10 ml-8">
        {restaurant?.menu.length === 0 ? (
          <h4 className="text-xl mb-5">No dishes yet</h4>
        ) : (
          <div className="grid mt-16 md:grid-cols-3 gap-x-5 gap-y-10">
            {restaurant?.menu.map((dish, index) => (
              <Dish
                isSelected={isSelected(dish.id)}
                id={dish.id}
                orderStarted={orderStarted}
                key={index}
                name={dish.name}
                description={dish.description}
                price={dish.price}
                isCustomer={true}
                options={dish.options}
                addItemToOrder={addItemToOrder}
                removeFromOrder={removeFromOrder}
                photo={dish.photo}
              >
                {dish.options?.map((option, index) => (
                  <DishOption
                    key={index}
                    dishId={dish.id}
                    isCustomer={true}
                    isSelected={isOptionSelected(dish.id, option.name)}
                    name={option.name}
                    extra={option.extra}
                    addOptionToItem={addOptionToItem}
                    removeOptionFromItem={removeOptionFromItem}
                  />
                ))}
              </Dish>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

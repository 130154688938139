import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  makeVar,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { LOCALSTORAGE_TOKEN } from "./constants";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { persistCache } from "apollo-cache-persist";

const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
export const isLoggedInVar = makeVar(Boolean(token));
export const authTokenVar = makeVar(token);

const isProduction = process.env.NODE_ENV === "production";

const wsLink = new WebSocketLink({
  uri: isProduction
    ? "wss://practice-domain-9255.com/api/graphql"
    : "ws://localhost:4000/api/graphql",
  options: {
    reconnect: true,
    connectionParams: {
      "x-jwt": authTokenVar() || "",
    },
    connectionCallback: () => {
      console.log("WebSocket conencted");
    },
  },
});

const httpLink = createHttpLink({
  uri: isProduction
    ? "https://practice-domain-9255.com/api/graphql"
    : "http://localhost:4000/api/graphql",
});

const authLink = setContext((_, previousContext) => {
  const { headers } = previousContext;
  return {
    headers: { ...headers, "x-jwt": authTokenVar() || "" },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

const cache = new InMemoryCache();

export const client = new ApolloClient({
  link: splitLink,
  cache,
});

import React, { useState } from "react";
import { Link } from "react-router-dom";

interface IDropDownProps {
  id: string;
}

const DropdownButton: React.FC<IDropDownProps> = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="ml-8 relative inline-block text-left">
      <button
        onClick={toggleDropdown}
        className="inline-flex items-center justify-center w-full rounded-md border border-gray-600 shadow-sm px-8 py-4 bg-gray-800 text-white text-lg font-medium hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500 transition ease-in-out duration-150"
      >
        Actions
      </button>

      {isOpen && (
        <div className="origin-top-right absolute left-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
          <div className="py-1">
            <Link
              to={`/`}
              className="block px-6 py-3 text-lg text-gray-700 hover:bg-gray-100 hover:text-gray-900 transition ease-in-out duration-150"
            >
              Go Back To My Restaurants &rarr;
            </Link>
            <Link
              to={`/restaurant/${id}/add-dish`}
              className="block px-6 py-3 text-lg text-gray-700 hover:bg-gray-100 hover:text-gray-900 transition ease-in-out duration-150"
            >
              Add Dish &rarr;
            </Link>
            <Link
              to={`/restaurant/buy-promotion`}
              className="block px-6 py-3 text-lg text-gray-700 hover:bg-gray-100 hover:text-gray-900 transition ease-in-out duration-150"
            >
              Buy Promotion &rarr;
            </Link>
            <Link
              to={`/edit-restaurant/${id}`}
              className="block px-6 py-3 text-lg text-gray-700 hover:bg-gray-100 hover:text-gray-900 transition ease-in-out duration-150"
            >
              Edit Restaurant &rarr;
            </Link>
            <Link
              to={`/delete-restaurant/${id}`}
              className="block px-6 py-3 text-lg text-gray-700 hover:bg-gray-100 hover:text-gray-900 transition ease-in-out duration-150"
            >
              Delete Restaurant &rarr;
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownButton;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../pages/login";
import CreateAccount from "../pages/create-account";
import { NotFound } from "../pages/404";

const LoggedOutRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="create-account" element={<CreateAccount />}></Route>
        <Route path="/" element={<Login />}></Route>
        <Route path="*" element={<NotFound></NotFound>}></Route>
      </Routes>
    </Router>
  );
};

export default LoggedOutRouter;

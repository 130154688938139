import { gql, useMutation, useQuery, useSubscription } from "@apollo/client";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
// import { FULL_ORDER_FRAGMENT } from "../fragments";
import { useMe } from "../hooks/useMe";
// import { editOrder, editOrderVariables } from "../__generated__/editOrder";
// import { getOrder, getOrderVariables } from "../__generated__/getOrder";
// import { OrderStatus, UserRole } from "../__generated__/globalTypes";
// import {
//   orderUpdates,
//   orderUpdatesVariables,
// } from "../__generated__/orderUpdates";
import { OrderStatus, UserRole } from "../gql/graphql";

const GET_ORDER = gql`
  query getOrder($input: GetOrderInput!) {
    getOrder(input: $input) {
      ok
      error
      order {
        id
        status
        total
        customer {
          email
        }
        restaurant {
          name
        }
        driver {
          email
        }
      }
    }
  }
`;

const ORDER_SUBSCRIPTION = gql`
  subscription orderUpdates($input: OrderUpdatesInput!) {
    orderUpdates(input: $input) {
      id
      status
      total
      driver {
        email
      }
      customer {
        email
      }
      restaurant {
        name
      }
    }
  }
`;

const EDIT_ORDER = gql`
  mutation editOrder($input: EditOrderInput!) {
    editOrder(input: $input) {
      ok
      error
    }
  }
`;

export const Order = () => {
  const { id } = useParams();

  if (!id) {
    return;
  }

  const { data: userData } = useMe();
  const [editOrderMutation] = useMutation(EDIT_ORDER);

  const { data, subscribeToMore } = useQuery(GET_ORDER, {
    variables: {
      input: {
        id: +id,
      },
    },
  });

  useEffect(() => {
    if (data?.getOrder.ok) {
      subscribeToMore({
        document: ORDER_SUBSCRIPTION,
        variables: {
          input: {
            id: +id,
          },
        },
        updateQuery: (
          prev,
          { subscriptionData: { data } }: { subscriptionData: { data: any } }
        ) => {
          if (!data) return prev;
          // return {
          //   getOrder: {
          //     ...prev.getOrder,
          //   },
          // };
          return {
            getOrder: {
              ...prev.getOrder,
              order: {
                ...data.orderUpdates,
              },
            },
          };
        },
      });
    }
  }, [data, subscribeToMore]);

  const onButtonClick = (newStatus: any) => {
    editOrderMutation({
      variables: {
        input: {
          id: +id,
          status: newStatus,
        },
      },
    });
  };

  const order = data?.getOrder.order;
  const restaurant = data?.getOrder.order.restaurant;
  const customer = data?.getOrder.order.customer;
  const driver = data?.getOrder.order.driver;

  useEffect(() => {
    console.log(data);
  });

  return (
    <div className="mt-32 container flex justify-center">
      <Helmet>
        <title>Order #{id} | Nuber Eats</title>
      </Helmet>
      <div className="border border-gray-800 w-full max-w-screen-sm flex flex-col justify-center">
        <h4 className="bg-gray-800 w-full py-5 text-white text-center text-xl">
          Order #{id}
        </h4>
        <h5 className="p-5 pt-10 text-3xl text-center ">${order?.total}</h5>
        <div className="p-5 text-xl grid gap-6">
          <div className="border-t pt-5 border-gray-700">
            Prepared By: <span className="font-medium">{restaurant?.name}</span>
          </div>
          <div className="border-t pt-5 border-gray-700 ">
            Deliver To: <span className="font-medium">{customer?.email}</span>
          </div>
          <div className="border-t border-b py-5 border-gray-700">
            Driver:{" "}
            <span className="font-medium">{driver?.email || "Not yet."}</span>
          </div>
          {userData?.me.role === UserRole.Client && (
            <span className=" text-center mt-5 mb-3  text-2xl text-lime-600">
              Status: {order?.status}
            </span>
          )}
          {userData?.me.role === UserRole.Owner && (
            <>
              {order?.status === OrderStatus.Pending && (
                <button
                  onClick={() => {
                    onButtonClick(OrderStatus.Cooking);
                  }}
                  className="btn"
                >
                  Accept Order
                </button>
              )}
              {order?.status === OrderStatus.Cooking && (
                <button
                  onClick={() => onButtonClick(OrderStatus.Cooked)}
                  className="btn"
                >
                  Order Cooked
                </button>
              )}
              {order?.status !== OrderStatus.Cooking &&
                order?.status !== OrderStatus.Pending && (
                  <span className=" text-center mt-5 mb-3  text-2xl text-lime-600">
                    Status: {order?.status}
                  </span>
                )}
            </>
          )}
          {userData?.me.role === UserRole.Delivery && (
            <>
              {order?.status === OrderStatus.Cooked && (
                <button
                  onClick={() => onButtonClick(OrderStatus.PickedUp)}
                  className="btn"
                >
                  Picked Up
                </button>
              )}
              {/* {data?.getOrder.order?.status === OrderStatus.PickedUp && ( */}
              {order?.status === OrderStatus.PickedUp && (
                <button
                  onClick={() => onButtonClick(OrderStatus.Delivered)}
                  className="btn"
                >
                  Order Delivered
                </button>
              )}
            </>
          )}
          {order?.status === OrderStatus.Delivered && (
            <span className=" text-center mt-5 mb-3  text-2xl text-lime-600">
              Thank you for using Nuber Eats
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

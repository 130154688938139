import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Button } from "../../components/button";
import { MY_RESTAURANTS_QUERY } from "./my-restaurants";
import { useNavigate, useParams } from "react-router-dom";
import { MY_RESTAURANT_QUERY } from "./my-restaurant";
import {
  EditRestaurantMutation,
  EditRestaurantMutationVariables,
  GetRestaurantForEditQuery,
  GetRestaurantForEditQueryVariables,
  MyRestaurantsQuery,
} from "../../gql/graphql";

export const EDIT_RESTAURANT_MUTATION = gql`
  mutation editRestaurant($input: EditRestaurantInput!) {
    editRestaurant(input: $input) {
      error
      ok
    }
  }
`;

const GET_RESTAURANT_FOR_EDIT_QUERY = gql`
  query getRestaurantForEdit($input: MyRestaurantInput!) {
    myRestaurant(input: $input) {
      ok
      error
      restaurant {
        name
        address
      }
    }
    allCategories {
      ok
      error
      categories {
        name
      }
    }
  }
`;

interface IFormProps {
  name: string;
  address: string;
  categoryName: string;
  file: FileList;
}

export const EditRestaurant = () => {
  const [imageUrl, setImageUrl] = useState("");
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();
  const { id: restaurantId } = useParams();

  if (!restaurantId) {
    return null;
  }
  const client = useApolloClient();

  // const queryResult = client.readQuery<
  //   GetRestaurantForEditQuery,
  //   GetRestaurantForEditQueryVariables
  // >({
  //   query: GET_RESTAURANT_FOR_EDIT_QUERY,
  //   variables: {
  //     input: {
  //       id: +restaurantId,
  //     },
  //   },
  // });

  const { data } = useQuery<
    GetRestaurantForEditQuery,
    GetRestaurantForEditQueryVariables
  >(GET_RESTAURANT_FOR_EDIT_QUERY, {
    variables: {
      input: {
        id: +restaurantId,
      },
    },
    // skip: !!queryResult,
  });

  const categories = data?.allCategories.categories;

  const { register, getValues, formState, handleSubmit, setValue, reset } =
    useForm<IFormProps>({
      mode: "onChange",
    });

  useEffect(() => {
    if (data) {
      reset({
        name: data.myRestaurant.restaurant?.name,
        address: data.myRestaurant.restaurant?.address,
      });
    }
  }, [data]);

  // useEffect(() => {
  //   if (!queryResult && data?.myRestaurant.restaurant) {
  //     setValue("name", data.myRestaurant.restaurant.name);
  //     setValue("address", data.myRestaurant.restaurant.address);
  //     // setValue("price", `${data.dish.dish.price}`);
  //   }
  // }, [data, setValue, queryResult]);

  // useEffect(() => {
  //   if (queryResult?.dish?.dish) {
  //     setValue("name", queryResult.dish.dish.name);
  //     setValue("description", queryResult.dish.dish.description);
  //     setValue("price", `${queryResult.dish.dish.price}`);
  //   }
  // }, [queryResult, setValue]);

  const onCompleted = (data: any) => {
    if (data.editRestaurant.ok) {
      const { name, categoryName, address } = getValues();
      setUploading(false);
      const queryResult = client.readQuery<MyRestaurantsQuery>({
        query: MY_RESTAURANTS_QUERY,
      });

      if (queryResult) {
        client.writeQuery<MyRestaurantsQuery>({
          query: MY_RESTAURANTS_QUERY,
          data: {
            myRestaurants: {
              __typename: "MyRestaurantsOutput",
              ok: queryResult?.myRestaurants?.ok ?? false,
              error: queryResult?.myRestaurants.error,
              restaurants: queryResult?.myRestaurants.restaurants?.map(
                (restaurant) =>
                  restaurant.id === +restaurantId
                    ? {
                        __typename: "Restaurant",
                        id: +restaurantId,
                        name,
                        coverImg: imageUrl,
                        address,
                        category: {
                          __typename: "Category",
                          name: categoryName,
                        },
                        isPromoted: false,
                      }
                    : restaurant
              ),
            },
          },
        });
      }
      navigate("/", { replace: true });
    }
  };

  const onError = (error: any) => {
    console.error("Mutation error:", error);
    alert("Failed to edit restaurant. Please try again.");
    setUploading(false);
    navigate("/", { replace: true });
  };

  const [editRestaurantMutation] = useMutation<
    EditRestaurantMutation,
    EditRestaurantMutationVariables
  >(EDIT_RESTAURANT_MUTATION, {
    onCompleted,
    onError,
  });

  const onSubmit = async () => {
    try {
      setUploading(true);
      const { file, name, categoryName, address } = getValues();
      const actualFile = file[0];
      const formBody = new FormData();
      formBody.append("file", actualFile);

      const response = await fetch("http://localhost:4000/uploads", {
        method: "POST",
        body: formBody,
      });
      if (!response.ok) {
        throw new Error(`Failed to upload image: ${response.statusText}`);
      }
      const { url: coverImg } = await response.json();
      setImageUrl(coverImg);

      editRestaurantMutation({
        variables: {
          input: {
            name,
            coverImg,
            address,
            categoryName,
            restaurantId: +restaurantId,
          },
        },
      });
    } catch (error) {
      alert(error);
      navigate(`/restaurants/${restaurantId}`, { replace: true });
    }
  };

  return (
    <div className="container flex flex-col items-center mt-52">
      <Helmet>
        <title>Edit Restaurant | Nuber Eats</title>
      </Helmet>
      <h4 className="font-semibold text-2xl mb-3">Edit Restaurant</h4>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid max-w-screen-sm gap-3 mt-5 w-full mb-5"
      >
        <input
          {...register("name", {
            required: "Name is required",
            minLength: {
              value: 5,
              message: "Name must be at least 5 characters long.",
            },
          })}
          className="input"
          type="text"
          name="name"
          placeholder="Name"
        />
        {formState?.errors?.name && (
          <p className="font-medium text-red-500">
            {formState.errors.name.message}
          </p>
        )}
        <input
          {...register("address", { required: "Address is required." })}
          className="input"
          type="text"
          name="address"
          placeholder="Address"
        />
        {formState?.errors?.address && (
          <p className="font-medium text-red-500">
            {formState.errors.address.message}
          </p>
        )}

        <select
          {...register("categoryName", {
            required: "Category Name is required",
          })}
          className="input"
          name="categoryName"
          defaultValue=""
        >
          <option value="" disabled hidden>
            Select a Category
          </option>
          {categories?.map((category: any, index: any) => (
            <option key={index} value={category.name}>
              {category.name}
            </option>
          ))}
        </select>

        <input
          {...register("file", { required: "Image file is required" })}
          type="file"
          name="file"
          accept="image/*"
        />
        {formState?.errors?.file && (
          <p className="font-medium text-red-500">
            {formState.errors.file.message}
          </p>
        )}
        <Button
          loading={uploading}
          canClick={formState.isValid}
          actionText="Edit Restaurant"
        />
      </form>
      <button
        onClick={() => navigate(-1)}
        className="w-5/12 text-lg font-medium focus:outline-none text-white py-4  transition-colors bg-gray-300 hover:bg-gray-700"
      >
        Cancel
      </button>
    </div>
  );
};

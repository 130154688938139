import React from "react";

interface IDishOptionProps {
  isSelected: boolean;
  name: string;
  extra?: number | null;
  dishId: number;
  isCustomer: boolean;
  addOptionToItem?: (dishId: number, optionName: string) => void;
  removeOptionFromItem?: (dishId: number, optionName: string) => void;
}

export const DishOption: React.FC<IDishOptionProps> = ({
  isSelected,
  name,
  extra,
  addOptionToItem,
  removeOptionFromItem,
  dishId,
  isCustomer,
}) => {
  const onClick = () => {
    console.log("isSelected", isSelected);
    console.log(isCustomer);
    if (!isCustomer) return; // If not a customer, do nothing on click
    // Safely call the functions if they are provided
    if (isSelected) {
      removeOptionFromItem?.(dishId, name);
    } else {
      addOptionToItem?.(dishId, name);
    }
  };

  return (
    <span
      onClick={onClick}
      className={`px-2 py-1 ${
        isSelected
          ? "bg-lime-600"
          : "bg-white text-black hover:bg-lime-600 hover:text-white"
      } ${!isCustomer && "cursor-default"}`} // Disable cursor interaction if not customer
    >
      <span className="mr-2">{name}</span>
      <span className="text-sm opacity-75">(${extra})</span>
    </span>
  );
};

// import React from "react";

// interface IDishOptionProps {
//   isSelected: boolean;
//   name: string;
//   extra?: number | null;
//   dishId: number;
//   isCustomer: boolean;
//   addOptionToItem: (dishId: number, optionName: string) => void;
//   removeOptionFromItem: (dishId: number, optionName: string) => void;
// }

// export const DishOption: React.FC<IDishOptionProps> = ({
//   isSelected,
//   name,
//   extra,
//   addOptionToItem,
//   removeOptionFromItem,
//   dishId,
//   isCustomer,
// }) => {
//   const onClick = () => {
//     if (!isCustomer) return;

//     if (isSelected) {
//       removeOptionFromItem(dishId, name);
//     } else {
//       addOptionToItem(dishId, name);
//     }
//   };
//   return (
//     <span
//       onClick={onClick}
//       className={`px-2 py-1 ${
//         isSelected
//           ? "bg-lime-600"
//           : "bg-white text-black hover:bg-lime-600 hover:text-white"
//       }`}
//     >
//       <span className="mr-2">{name}</span>
//       <span className="text-sm opacity-75">(${extra})</span>
//     </span>
//   );
// };

// // import React from "react";

// // interface IDishOptionProps {
// //   isSelected: boolean;
// //   name: string;
// //   extra?: number | null;
// //   dishId: number;
// //   addOptionToItem: (dishId: number, optionName: string) => void;
// //   removeOptionFromItem: (dishId: number, optionName: string) => void;
// // }

// // export const DishOption: React.FC<IDishOptionProps> = ({
// //   isSelected,
// //   name,
// //   extra,
// //   addOptionToItem,
// //   removeOptionFromItem,
// //   dishId,
// // }) => {
// //   const onClick = () => {
// //     if (isSelected) {
// //       removeOptionFromItem(dishId, name);
// //     } else {
// //       addOptionToItem(dishId, name);
// //     }
// //   };
// //   console.log(isSelected);
// //   return (
// //     <span
// //       onClick={onClick}
// //       //   className="border px-2 py-1 hover:border-gray-800"
// //       className={`border px-2 py-1 ${
// //         isSelected ? "border-gray-800" : "hover:border-gray-800"
// //       }`}
// //     >
// //       <span className="mr-2">{name}</span>
// //       {<span className="text-sm opacity-75">(${extra})</span>}
// //     </span>
// //   );
// // };

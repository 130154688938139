import React, { useEffect, useState } from "react";
import GoogleMapReact, { Position } from "google-map-react";
import { gql, useMutation, useSubscription } from "@apollo/client";
// import { coockedOrders } from "../../__generated__/coockedOrders";
import { Link, useNavigate } from "react-router-dom";
import { CoockedOrdersSubscription } from "../../gql/graphql";
// import { takeOrder, takeOrderVariables } from "../../__generated__/takeOrder";

const COOCKED_ORDERS_SUBSCRIPTION = gql`
  subscription coockedOrders {
    cookedOrders {
      id
      status
      total
      driver {
        email
      }
      customer {
        email
      }
      restaurant {
        name
        address
      }
    }
  }
`;

const TAKE_ORDER_MUTATION = gql`
  mutation takeOrder($input: TakeOrderInput!) {
    takeOrder(input: $input) {
      ok
      error
    }
  }
`;

interface ICoords {
  lat: number;
  lng: number;
}

interface IDriverProps {
  lat: number;
  lng: number;
  $hover?: any;
}

const Driver: React.FC<{ lat: number; lng: number }> = () => (
  <div className="text-6xl">🛵</div>
);

export const Dashboard = () => {
  const navigate = useNavigate();
  const [driverCoords, setDriverCoords] = useState<ICoords>({
    lng: 0,
    lat: 0,
  });
  const [map, setMap] = useState<google.maps.Map>();
  const [maps, setMaps] = useState<any>();

  // @ts-ignore
  const onSucces = ({ coords: { latitude, longitude } }) => {
    console.log("Detected Location:", { latitude, longitude }); // Log the location
    setDriverCoords({ lat: latitude, lng: longitude });
  };

  // @ts-ignore
  const onError = (error: GeolocationPositionError) => {
    console.log(error);
    // setIsLoading(false);
  };

  useEffect(() => {
    navigator.geolocation.watchPosition(onSucces, onError, {
      enableHighAccuracy: true,
    });
  }, []);

  useEffect(() => {
    if (map && maps) {
      map.panTo(new google.maps.LatLng(driverCoords.lat, driverCoords.lng));
      // const geocoder = new maps.Geocoder();
      // geocoder.geocode(
      //   {
      //     location: new google.maps.LatLng(driverCoords.lat, driverCoords.lng),
      //   },
      //   (results: any, status: any) => {
      //     console.log(status, results);
      //   }
      // );
    }
  }, [driverCoords.lat, driverCoords.lng]);

  const onApiLoaded = ({ map, maps }: { map: any; maps: any }) => {
    map.panTo(new google.maps.LatLng(driverCoords.lat, driverCoords.lng));
    setMap(map);
    setMaps(maps);
  };

  // const geocodeAddress = (
  //   address: string,
  //   callback: (lat: number, lng: number) => void
  // ) => {
  //   const geocoder = new google.maps.Geocoder();

  //   geocoder.geocode({ address: address }, (results, status) => {
  //     if (status === "OK") {
  //       const location = results[0].geometry.location;
  //       callback(location.lat(), location.lng());
  //     } else {
  //       console.error(
  //         "Geocode was not successful for the following reason: " + status
  //       );
  //     }
  //   });
  // };

  const { data: coockedOrdersData } =
    useSubscription<CoockedOrdersSubscription>(COOCKED_ORDERS_SUBSCRIPTION);

  const geocodeAddress = async (
    address: string
  ): Promise<{ lat: number; lng: number }> => {
    return new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          address,
        },
        (results, status) => {
          if (status === "OK") {
            const location = results[0].geometry.location;
            resolve({ lat: location.lat(), lng: location.lng() });
          } else {
            reject(
              new Error(
                "Geocode was not successful for the following reason: " + status
              )
            );
          }
        }
      );
    });
  };

  const makeRoute = async () => {
    if (!coockedOrdersData?.cookedOrders.restaurant?.address) {
      console.log("Restaurant address is not found");
      return;
    }

    try {
      const destination = await geocodeAddress(
        coockedOrdersData?.cookedOrders.restaurant?.address
      );
      if (map) {
        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer({
          polylineOptions: {
            strokeColor: "#000",
            strokeOpacity: 1,
            strokeWeight: 5,
          },
        });
        directionsRenderer.setMap(map);
        directionsService.route(
          {
            origin: {
              location: new google.maps.LatLng(
                driverCoords.lat,
                driverCoords.lng
              ),
            },
            destination: {
              location: new google.maps.LatLng(
                destination.lat,
                destination.lng
              ),
            },
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (result) => {
            directionsRenderer.setDirections(result);
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (coockedOrdersData?.cookedOrders.id) {
      // show the preview?
      // 25.10 cooked Order Subscription - 3:09
      makeRoute();
    }
  }, [coockedOrdersData]);

  const onCompleted = (data: any) => {
    if (data.takeOrder.ok) {
      navigate(`/orders/${coockedOrdersData?.cookedOrders.id}`);
    }
  };

  const [takeOrderMutation] = useMutation(TAKE_ORDER_MUTATION, {
    onCompleted,
  });

  const triggerMutation = (orderId: number) => {
    takeOrderMutation({
      variables: {
        input: {
          id: orderId,
        },
      },
    });
  };

  return (
    <div>
      <div
        className="overflow-hidden"
        style={{ width: window.innerWidth, height: "50vh" }}
      >
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={onApiLoaded}
          defaultZoom={17}
          defaultCenter={driverCoords}
          bootstrapURLKeys={{ key: "AIzaSyAe6rGs6PA03McfFjLI09KmwKPvQkY_kh8" }}
        >
          <Driver lat={driverCoords.lat} lng={driverCoords.lng}></Driver>
        </GoogleMapReact>
      </div>
      <div className=" max-w-screen-sm mx-auto bg-white relative -top-10 shadow-lg py-8 px-5">
        {coockedOrdersData?.cookedOrders.restaurant ? (
          <>
            <h1 className="text-center  text-3xl font-medium">
              New Coocked Order
            </h1>
            <h1 className="text-center my-3 text-2xl font-medium">
              Pick it up soon at{" "}
              {coockedOrdersData?.cookedOrders.restaurant?.name}
            </h1>
            <button
              onClick={() =>
                triggerMutation(coockedOrdersData?.cookedOrders.id)
              }
              className="btn w-full  block  text-center mt-5"
            >
              Take Order &rarr;
            </button>
          </>
        ) : (
          <h1 className="text-center  text-3xl font-medium">
            No orders yet...
          </h1>
        )}
      </div>
      {/* <button onClick={onGetRouteClick}>Get route</button> */}
    </div>

    // <div>
    //   <div
    //     className="overflow-hidden"
    //     style={{ width: window.innerWidth, height: "50vh" }}
    //   >
    //     <GoogleMapReact
    //       yesIWantToUseGoogleMapApiInternals
    //       //   onGoogleApiLoaded={onApiLoaded}
    //       defaultZoom={16}
    //       draggable={true}
    //       defaultCenter={{
    //         lat: 36.58,
    //         lng: 125.95,
    //       }}
    //       bootstrapURLKeys={{ key: "AIzaSyDBoOMuQsFzQJJ-UAxEItgE3K3-JfH7opA" }}
    //     ></GoogleMapReact>
    //   </div>
    //   <div className=" max-w-screen-sm mx-auto bg-white relative -top-10 shadow-lg py-8 px-5">
    //     {coockedOrdersData?.cookedOrders.restaurant ? (
    //       <>
    //         <h1 className="text-center  text-3xl font-medium">
    //           New Coocked Order
    //         </h1>
    //         <h1 className="text-center my-3 text-2xl font-medium">
    //           Pick it up soon @{" "}
    //           {coockedOrdersData?.cookedOrders.restaurant?.name}
    //         </h1>
    //         <button
    //           onClick={() =>
    //             triggerMutation(coockedOrdersData?.cookedOrders.id)
    //           }
    //           className="btn w-full  block  text-center mt-5"
    //         >
    //           Accept Challenge &rarr;
    //         </button>
    //       </>
    //     ) : (
    //       <h1 className="text-center  text-3xl font-medium">
    //         No orders yet...
    //       </h1>
    //     )}
    //   </div>
    // </div>
  );
};

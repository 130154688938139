import { useNavigate } from "react-router-dom";
import { isLoggedInVar, authTokenVar } from "../apollo"; // assuming you're using Apollo Client for state management
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApolloClient } from "@apollo/client"; // Import Apollo Client hook

export const LogoutButton = () => {
  const navigate = useNavigate();
  const client = useApolloClient(); // Get Apollo Client instance

  const logout = async () => {
    // 1. Clear the authentication token from localStorage
    localStorage.removeItem("nuber-eats-token");

    // 2. Update the Apollo Client reactive variables
    authTokenVar(null); // or use authTokenVar("") if you initialized it with a string
    isLoggedInVar(false);

    await client.clearStore();
    await new Promise((resolve) => setTimeout(resolve, 0));

    // 3. Redirect to the login page or home page
    navigate("/");
  };

  return (
    <button
      onClick={logout}
      className="flex items-center space-x-2 bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded transition-colors duration-300"
    >
      <FontAwesomeIcon icon={faSignOutAlt} />
      <span>Logout</span>
    </button>
  );
};

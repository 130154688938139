import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useMe } from "../hooks/useMe";
import { NotFound } from "../pages/404";
import { Header } from "../components/header";
import { ConfirmEmail } from "../pages/user/confirm-email";
import { EditProfile } from "../pages/user/edit-profile";
import { Restaurant } from "../pages/client/restaurant";
import { Restaurants } from "../pages/client/restaurants";
import { MyRestaurant } from "../pages/owner/my-restaurant";
import { MyRestaurants } from "../pages/owner/my-restaurants";
import { AddRestaurant } from "../pages/owner/add-restaurant";
import { Category } from "../pages/client/category";
import { Search } from "../pages/client/search";
import { AddDish } from "../pages/owner/add-dish";
import { EditRestaurant } from "../pages/owner/edit-restaurant";
import { DeleteRestaurant } from "../pages/owner/delete-restaurant";
import { OwnerDishPage } from "../pages/owner/dish";
import { ClientDishPage } from "../pages/client/clientDish";
import { EditDish } from "../pages/owner/edit-dish";
import { DeleteDish } from "../pages/owner/delete-dish";
import { Order } from "../pages/order";
import { Dashboard } from "../pages/driver/dashboard";
import { UserRole } from "../gql/graphql";

const clientRoutes = [
  {
    path: "/",
    component: <Restaurants />,
  },
  {
    path: "/search",
    component: <Search />,
  },
  {
    path: "/category/:slug",
    component: <Category />,
  },
  {
    path: "/restaurant/:id",
    component: <Restaurant />,
  },
  {
    path: "/restaurant/:restaurantId/dish/:dishId",
    component: <ClientDishPage />,
  },
];

const commonRoutes = [
  { path: "/confirm", component: <ConfirmEmail /> },
  { path: "/edit-profile", component: <EditProfile /> },
  { path: "/orders/:id", component: <Order /> },
];

const restaurantRoutes = [
  { path: "/", component: <MyRestaurants /> },
  { path: "/add-restaurant", component: <AddRestaurant /> },
  { path: "/edit-restaurant/:id", component: <EditRestaurant /> },
  { path: "/delete-restaurant/:id", component: <DeleteRestaurant /> },
  { path: "/restaurant/:id", component: <MyRestaurant /> },
  { path: "/restaurant/:restaurantId/add-dish", component: <AddDish /> },
  {
    path: "/restaurant/:restaurantId/dish/:dishId",
    component: <OwnerDishPage />,
  },
  {
    path: "/restaurant/:restaurantId/edit-dish/:dishId",
    component: <EditDish />,
  },
  {
    path: "/restaurant/:restaurantId/delete-dish/:dishId",
    component: <DeleteDish />,
  },
];

const driverRoutes = [{ path: "/", component: <Dashboard /> }];

const LoggedInRouter = () => {
  const { data, loading, error } = useMe();

  if (!data || loading || error) {
    return (
      <div className="h-screen flex justify-center items-center">
        <span className="font-medium text-xl tracking-wide">Loading...</span>
      </div>
    );
  }

  return (
    <Router>
      <Header />
      <Routes>
        {data.me.role === UserRole.Client && (
          <>
            {clientRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.component}
              />
            ))}
          </>
        )}
        {data.me.role === UserRole.Owner && (
          <>
            {restaurantRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.component}
              />
            ))}
          </>
        )}
        {data.me.role === UserRole.Delivery && (
          <>
            {driverRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.component}
              />
            ))}
          </>
        )}
        {commonRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={route.component} />
        ))}
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </Router>
  );
};

export default LoggedInRouter;

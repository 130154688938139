import { gql, useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Button } from "../../components/button";
import { FormError } from "../../components/form-error";
import { MY_RESTAURANTS_QUERY } from "./my-restaurants";
import { useNavigate, useParams } from "react-router-dom";
import {
  DeleteRestaurantMutation,
  DeleteRestaurantMutationVariables,
  DeleteRestaurantOutput,
} from "../../gql/graphql";

const DELETE_RESTAURANT_MUTATION = gql`
  mutation deleteRestaurant($input: DeleteRestaurantInput!) {
    deleteRestaurant(input: $input) {
      error
      ok
    }
  }
`;

interface IFormProps {
  confirmation: string;
}

export const DeleteRestaurant = () => {
  const navigate = useNavigate();
  const { id: restaurantId } = useParams();
  if (!restaurantId) {
    return null;
  }
  const client = useApolloClient();
  const [deleting, setDeleting] = useState(false);

  const onCompleted = (data: { deleteRestaurant: DeleteRestaurantOutput }) => {
    const { ok, error } = data.deleteRestaurant;

    if (ok) {
      setDeleting(false);
      const queryResult = client.readQuery({ query: MY_RESTAURANTS_QUERY });
      const updatedRestaurants = queryResult.myRestaurants.restaurants.filter(
        (restaurant: any) => restaurant.id !== Number(restaurantId)
      );
      client.writeQuery({
        query: MY_RESTAURANTS_QUERY,
        data: {
          myRestaurants: {
            ...queryResult.myRestaurants,
            restaurants: updatedRestaurants,
          },
        },
      });
      alert("Deteled: ok");
      navigate("/");
    }
  };

  const onError = (error: any) => {
    console.error("Mutation error:", error);
    alert("Failed to delete restaurant. Please try again.");
    navigate("/", { replace: true });
  };

  const [deleteRestaurantMutation] = useMutation<
    DeleteRestaurantMutation,
    DeleteRestaurantMutationVariables
  >(DELETE_RESTAURANT_MUTATION, {
    onCompleted,
    onError,
  });
  const { register, formState, handleSubmit } = useForm<IFormProps>({
    mode: "onChange",
  });
  const onSubmit = async () => {
    try {
      setDeleting(true);
      deleteRestaurantMutation({
        variables: {
          input: {
            restaurantId: +restaurantId,
          },
        },
      });
    } catch (error) {
      alert(error);
      navigate("/", { replace: true });
    }
  };

  return (
    <div className="container flex flex-col items-center mt-52">
      <Helmet>
        <title>Delete Restaurant | Nuber Eats</title>
      </Helmet>
      <h4 className="font-semibold text-2xl mb-3">Delete Restaurant</h4>
      <h4 className="text-xl mb-3">
        To confirm deletion, type permanently delete in the text input field.
      </h4>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid max-w-screen-sm gap-3 mt-5 w-full mb-5"
      >
        <input
          {...register("confirmation", {
            required: true,
            validate: (value) =>
              value === "permanently delete" ||
              "You must type 'permanently delete' to confirm",
          })}
          className="input"
          type="text"
          name="confirmation"
          placeholder="permanently delete"
        />
        {formState?.errors?.confirmation && (
          <span className="font-medium text-red-500">
            {formState?.errors?.confirmation.message}
          </span>
        )}
        <Button
          loading={deleting}
          canClick={formState.isValid}
          actionText="Delete Restaurant"
        />
      </form>
      <button
        onClick={() => navigate(-1)}
        className="w-5/12 text-lg font-medium focus:outline-none text-white py-4  transition-colors bg-gray-300 hover:bg-gray-700"
      >
        Cancel
      </button>
    </div>
  );
};

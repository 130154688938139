import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../components/button";

import { MY_RESTAURANT_QUERY } from "./my-restaurant";
import {
  CreateDishMutation,
  CreateDishMutationVariables,
  MyRestaurantQuery,
  MyRestaurantQueryVariables,
} from "../../gql/graphql";

const CREATE_DISH_MUTATION = gql`
  mutation createDish($input: CreateDishInput!) {
    createDish(input: $input) {
      ok
      error
    }
  }
`;

interface IFormBase {
  name: string;
  price: string;
  description: string;
  file: FileList;
  // [key: string]: string | FileList;
}

interface IDynamicFields {
  [key: string]: string;
}

type IForm = IFormBase & IDynamicFields;

export const AddDish = () => {
  const { restaurantId } = useParams();
  if (!restaurantId) {
    return null;
  }
  const { data } = useQuery<MyRestaurantQuery, MyRestaurantQueryVariables>(
    MY_RESTAURANT_QUERY,
    {
      variables: {
        input: {
          id: +restaurantId,
        },
      },
    }
  );

  const navigate = useNavigate();
  const [createDishMutation, { loading }] = useMutation<
    CreateDishMutation,
    CreateDishMutationVariables
  >(CREATE_DISH_MUTATION, {
    refetchQueries: [
      {
        query: MY_RESTAURANT_QUERY,
        variables: {
          input: {
            id: +restaurantId,
          },
        },
      },
    ],
    onError: (error) => {
      console.error("Mutation error:", error);
      alert("Failed to add dish. Please try again.");
      navigate("/", { replace: true });
    },
  });

  const {
    register,
    handleSubmit,
    formState,
    getValues,
    setValue,
    watch,
    trigger,
    unregister,
  } = useForm<IForm>({
    mode: "onChange",
  });

  const [optionsNumber, setOptionsNumber] = useState<number[]>([]);

  const watchFields = watch();

  const onSubmit = async () => {
    try {
      const { name, price, description, file, ...rest } = getValues();
      const actualFile = file[0];
      const formBody = new FormData();
      formBody.append("file", actualFile);
      const uploadUrl =
        process.env.NODE_ENV === "development"
          ? "http://localhost:4000/api/uploads/"
          : "http://nuber-eats-alb-739500117.ap-northeast-2.elb.amazonaws.com/api/uploads";
      const { url: photo } = await (
        await fetch(uploadUrl, {
          method: "POST",
          body: formBody,
        })
      ).json();

      // If optionsNumber is an empty array, the map function will never be executed.
      // Therefore, it will return an empty array.
      const optionObjects = optionsNumber.map((theId) => {
        const optionName = rest[`${theId}-optionName`];
        const optionExtra = rest[`${theId}-optionExtra`];

        // return {
        //   name: typeof optionName === "string" ? optionName : "",
        //   extra: typeof optionExtra === "string" ? +optionExtra : 0,
        // };

        return {
          name: optionName,
          extra: +optionExtra,
        };
      });
      console.log(optionObjects);

      createDishMutation({
        variables: {
          input: {
            name,
            price: +price,
            description,
            photo,
            restaurantId: +restaurantId,
            options: optionObjects,
          },
        },
      });
      navigate(`/restaurant/${restaurantId}`, { replace: true });
    } catch (error) {
      alert(error);
      navigate("/", { replace: true });
    }
  };

  const onAddOptionClick = () => {
    setOptionsNumber((current) => [Date.now(), ...current]);
  };

  // useEffect(() => {
  //   // const currentWatchFields = watch();
  //   // console.log("Updated watch fields: ", currentWatchFields);
  //   console.log(watchFields);
  // }, [watchFields]);

  // useEffect(() => {
  //   const currentWatchFields = watch();
  //   console.log("Updated watch fields: ", currentWatchFields);
  //   // console.log(watchFields);
  // });

  const onDeleteClick = async (idToDelete: number) => {
    setOptionsNumber((current) => current.filter((id) => id !== idToDelete));
    unregister(`${idToDelete}-optionName`);
    unregister(`${idToDelete}-optionExtra`);
    // setValue(`${idToDelete}-optionName`, "");
    // setValue(`${idToDelete}-optionExtra`, "");
    await trigger(); // Revalidate the form after deleting an option
  };

  // Check if all options are filled when they exist
  const areOptionsValid = optionsNumber.every((theId) => {
    const optionName = watchFields[`${theId}-optionName`];
    const optionExtra = watchFields[`${theId}-optionExtra`];
    if (optionName && optionExtra) {
      return true;
    }
    // return optionName && optionExtra;
  });

  const canSubmit = formState.isValid && areOptionsValid;

  const restaurant = data?.myRestaurant.restaurant;
  return (
    <div>
      <Helmet>
        <title>Add Dish | Nuber Eats</title>
      </Helmet>
      <div
        className=" bg-gray-700  py-28 bg-center bg-cover"
        style={{
          backgroundImage: `url(${restaurant?.coverImg})`,
        }}
      ></div>
      <div className="container mt-10">
        <h2 className="text-4xl font-medium mb-10">
          {restaurant?.name || "Loading..."}
        </h2>
      </div>
      <div className="container flex flex-col items-center mt-5">
        <h4 className="font-semibold text-2xl mb-3">Add Dish</h4>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid max-w-screen-sm gap-3 mt-5 w-full mb-5"
        >
          <input
            {...register("name", {
              required: "Name is required.",
              minLength: {
                value: 5,
                message: "Name must be at least 5 characters long.",
              },
            })}
            className="input"
            type="text"
            name="name"
            placeholder="Name"
          />
          {formState?.errors?.name && (
            <p className="font-medium text-red-500">
              {formState.errors.name.message}
            </p>
          )}
          <input
            {...register("price", { required: "Price is required." })}
            className="input"
            type="number"
            name="price"
            min={0}
            placeholder="Price"
          />
          {formState?.errors?.price && (
            <p className="font-medium text-red-500">
              {formState.errors.price.message}
            </p>
          )}
          <input
            {...register("description", {
              required: "Description is required.",
            })}
            className="input"
            type="text"
            name="description"
            placeholder="Description"
          />
          {formState?.errors?.description && (
            <p className="font-medium text-red-500">
              {formState.errors.description.message}
            </p>
          )}

          <input
            {...register("file", { required: "Image file is required" })}
            type="file"
            name="file"
            accept="image/*"
          />
          {formState?.errors?.file && (
            <p className="font-medium text-red-500">
              {formState.errors.file.message}
            </p>
          )}
          <div className="my-10">
            <h4 className="font-medium  mb-3 text-lg">Dish Options</h4>
            <span
              onClick={onAddOptionClick}
              className="cursor-pointer text-white bg-gray-900 py-1 px-2 mt-5 bg-"
            >
              Add Dish Option
            </span>

            {optionsNumber.map((id) => (
              <div key={id} className="mt-5">
                <div className="flex items-start space-x-3">
                  {/* Option Name Input */}
                  <div className="flex flex-col w-full">
                    <input
                      {...register(`${id}-optionName`, {
                        required: "Option Name is required.",
                      })}
                      name={`${id}-optionName`}
                      className="py-2 px-4 focus:outline-none focus:border-gray-600 border-2"
                      type="text"
                      placeholder="Option Name"
                    />
                    {(formState.errors as any)?.[`${id}-optionName`] && (
                      <span className="block text-red-500 mt-1 h-5">
                        {(formState.errors as any)[`${id}-optionName`].message}
                      </span>
                    )}
                  </div>

                  {/* Option Extra Input */}
                  <div className="flex flex-col w-full">
                    <input
                      {...register(`${id}-optionExtra`, {
                        required: "Option Extra is required.",
                      })}
                      name={`${id}-optionExtra`}
                      className="py-2 px-4 focus:outline-none focus:border-gray-600 border-2"
                      type="number"
                      min={0}
                      placeholder="Option Extra"
                    />
                    {(formState.errors as any)?.[`${id}-optionExtra`] && (
                      <span className="block text-red-500 mt-1 h-5">
                        {(formState.errors as any)[`${id}-optionExtra`].message}
                      </span>
                    )}
                  </div>

                  {/* Delete Option Button */}
                  <div className="flex items-center flex-shrink-0">
                    <span
                      className="cursor-pointer text-white bg-red-500 py-2 px-4 h-full flex items-center justify-center"
                      onClick={() => onDeleteClick(id)}
                    >
                      Delete Option
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <Button
            loading={loading}
            // canClick={formState.isValid}
            canClick={canSubmit}
            actionText="Create Dish"
          />
        </form>
        <button
          onClick={() => navigate(-1)}
          className="w-1/2 text-lg font-medium focus:outline-none text-white py-4  transition-colors bg-gray-300 hover:bg-gray-700"
        >
          Cancel
        </button>
        {/* <button onClick={onClick} className="border-2 border-black">
          click me
        </button> */}
      </div>
    </div>
  );
};

import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Button } from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { DISH_QUERY } from "./dish";
import {
  DishQueryVariables,
  EditDishMutation,
  EditDishMutationVariables,
  GetDishForEditQuery,
  GetDishForEditQueryVariables,
} from "../../gql/graphql";

export const GET_DISH_FOR_EDIT_QUERY = gql`
  query getDishForEdit($dishInput: DishInput!) {
    dish(input: $dishInput) {
      ok
      error
      dish {
        name
        price
        description
      }
    }
  }
`;

const EDIT_DISH_MUTATION = gql`
  mutation editDish($input: EditDishInput!) {
    editDish(input: $input) {
      error
      ok
    }
  }
`;

interface IFormProps {
  name: string;
  price: string;
  description: string;
  file: FileList;
  [key: string]: string | FileList;
}

export const EditDish = () => {
  const navigate = useNavigate();
  const { restaurantId, dishId } = useParams<{
    restaurantId: string;
    dishId: string;
  }>();
  if (!restaurantId || !dishId) {
    return null;
  }
  const client = useApolloClient();

  const queryResult = client.readQuery<
    GetDishForEditQuery,
    GetDishForEditQueryVariables
  >({
    query: GET_DISH_FOR_EDIT_QUERY,
    variables: {
      dishInput: { dishId: +dishId },
    },
  });

  const { data } = useQuery<GetDishForEditQuery, GetDishForEditQueryVariables>(
    GET_DISH_FOR_EDIT_QUERY,
    {
      variables: {
        dishInput: {
          dishId: +dishId,
        },
      },
      skip: !!queryResult,
    }
  );

  const onCompleted = () => {
    navigate(-1);
  };

  const onError = (error: any) => {
    console.error("Mutation error:", error);
    alert("Failed to edit dish. Please try again.");
    setUploading(false);
    navigate("/", { replace: true });
  };

  const [editDishMutation] = useMutation<
    EditDishMutation,
    EditDishMutationVariables
  >(EDIT_DISH_MUTATION, {
    refetchQueries: [
      {
        query: DISH_QUERY,
        variables: {
          dishInput: {
            dishId: +dishId,
          },
          myRestaurantInput: {
            id: +restaurantId,
          },
        } as DishQueryVariables,
      },
    ],
    onCompleted,
    onError,
  });
  const { register, getValues, formState, handleSubmit, setValue } =
    useForm<IFormProps>({
      mode: "onChange",
      // defaultValues: {
      //   name: queryResult?.dish.dish?.name,
      //   description: queryResult?.dish.dish?.description,
      //   price: `${queryResult?.dish.dish?.price}`,
      // },
    });

  useEffect(() => {
    if (!queryResult && data?.dish.dish) {
      setValue("name", data.dish.dish.name);
      setValue("description", data.dish.dish.description);
      setValue("price", `${data.dish.dish.price}`);
    }
  }, [data, setValue, queryResult]);

  useEffect(() => {
    if (queryResult?.dish?.dish) {
      setValue("name", queryResult.dish.dish.name);
      setValue("description", queryResult.dish.dish.description);
      setValue("price", `${queryResult.dish.dish.price}`);
    }
  }, [queryResult, setValue]);

  const [uploading, setUploading] = useState(false);
  const onSubmit = async () => {
    try {
      setUploading(true);
      const { file, name, price, description, ...rest } = getValues();
      const actualFile = file[0];
      const formBody = new FormData();
      formBody.append("file", actualFile);
      const { url: photo } = await (
        await fetch("http://localhost:4000/uploads/", {
          method: "POST",
          body: formBody,
        })
      ).json();
      if (!restaurantId) {
        return;
      }

      const optionObjects = optionsNumber.map((theId) => {
        const optionName = rest[`${theId}-optionName`];
        const optionExtra = +rest[`${theId}-optionExtra`];

        const extraValue = optionExtra ? Number(optionExtra) : 0;

        return {
          name: typeof optionName === "string" ? optionName : "",
          extra: isNaN(extraValue) ? 0 : extraValue,

          // extra: typeof optionExtra === "string" ? +optionExtra : 0,
        };
      });

      editDishMutation({
        variables: {
          input: {
            dishId: +dishId,
            name,
            price: +price,
            description,
            photo,
            options: optionObjects,
          },
        },
      });
    } catch (error) {
      alert(error);
      navigate("/", { replace: true });
    }
  };
  const [optionsNumber, setOptionsNumber] = useState<number[]>([]);
  const onAddOptionClick = () => {
    setOptionsNumber((current) => [Date.now(), ...current]);
  };
  const onDeleteClick = (idToDelete: number) => {
    setOptionsNumber((current) => current.filter((id) => id !== idToDelete));
    setValue(`${idToDelete}-optionName`, "");
    setValue(`${idToDelete}-optionExtra`, "");
  };
  return (
    <div className="container flex flex-col items-center mt-52">
      <Helmet>
        <title>Edit Dish | Nuber Eats</title>
      </Helmet>
      <h4 className="font-semibold text-2xl mb-3">Edit Dish</h4>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid max-w-screen-sm gap-3 mt-5 w-full mb-5"
      >
        <input
          {...register("name", {
            required: "Name is required",
            minLength: {
              value: 5,
              message: "Name must be at least 5 characters long.",
            },
          })}
          className="input"
          type="text"
          name="name"
          placeholder="Name"
        />
        {formState?.errors?.name && (
          <p className="font-medium text-red-500">
            {formState.errors.name.message}
          </p>
        )}
        <input
          {...register("price", { required: "Price is required." })}
          className="input"
          type="number"
          name="price"
          min={0}
          placeholder="Price"
        />
        {formState?.errors?.price && (
          <p className="font-medium text-red-500">
            {formState.errors.price.message}
          </p>
        )}
        <input
          {...register("description", {
            required: "Description is required",
          })}
          className="input"
          type="text"
          name="description"
          placeholder="Description"
        />
        {formState?.errors?.description && (
          <p className="font-medium text-red-500">
            {formState.errors.description.message}
          </p>
        )}
        <input
          {...register("file", { required: "Image file is required" })}
          type="file"
          name="file"
          accept="image/*"
        />
        {formState?.errors?.file && (
          <p className="font-medium text-red-500">
            {formState.errors.file.message}
          </p>
        )}
        <div className="my-10">
          <h4 className="font-medium  mb-3 text-lg">Dish Options</h4>
          <span
            onClick={onAddOptionClick}
            className="cursor-pointer text-white bg-gray-900 py-1 px-2 mt-5 bg-"
          >
            Add Dish Option
          </span>
          {optionsNumber.length !== 0 &&
            optionsNumber.map((id) => (
              <div key={id} className="mt-5">
                <input
                  {...register(`${id}-optionName`)}
                  name={`${id}-optionName`}
                  className="py-2 px-4 focus:outline-none mr-3 focus:border-gray-600 border-2"
                  type="text"
                  placeholder="Option Name"
                />
                <input
                  {...register(`${id}-optionExtra`)}
                  name={`${id}-optionExtra`}
                  className="py-2 px-4 focus:outline-none focus:border-gray-600 border-2"
                  type="number"
                  min={0}
                  placeholder="Option Extra"
                />
                <span
                  className="cursor-pointer text-white bg-red-500 ml-3 py-3 px-4 mt-5 bg-"
                  onClick={() => onDeleteClick(id)}
                >
                  Delete Option
                </span>
              </div>
            ))}
        </div>
        <Button
          loading={uploading}
          canClick={formState.isValid}
          actionText="Edit Restaurant"
        />
      </form>
      <button
        onClick={() => navigate(-1)}
        className="w-5/12 text-lg font-medium focus:outline-none text-white py-4  transition-colors bg-gray-300 hover:bg-gray-700"
      >
        Cancel
      </button>
    </div>
  );
};

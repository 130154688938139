import React, { ReactNode } from "react";

interface IDishProps {
  id?: number;
  description: string;
  name: string;
  price: number;
  isCustomer?: boolean;
  orderStarted?: boolean;
  isSelected?: boolean;
  options?: any;
  children?: ReactNode;
  photo: string | null | undefined;
  addItemToOrder?: (dishId: number) => void;
  removeFromOrder?: (dishId: number) => void;
}

export const Dish: React.FC<IDishProps> = ({
  id = 0,
  description,
  name,
  price,
  isCustomer,
  orderStarted = false,
  options,
  isSelected,
  photo,
  addItemToOrder,
  removeFromOrder,
  children: dishOptions,
}) => {
  const onClick = () => {
    if (!isCustomer || !orderStarted) return; // If not customer, or order not started, do nothing

    // Safely call the functions if they are provided
    if (!isSelected && addItemToOrder) {
      return addItemToOrder(id);
    }
    if (isSelected && removeFromOrder) {
      return removeFromOrder(id);
    }
  };

  return (
    <div
      className={`flex justify-between items-center px-8 py-4 border-2 border-gray-300 cursor-pointer  transition-all ${
        isSelected ? "text-white bg-gray-800" : " text-black"
      }`}
    >
      <div>
        <div className="mb-5">
          <h3 className="text-lg font-medium flex items-center ">
            {name}{" "}
            {orderStarted && isCustomer && (
              <button
                className={`ml-3 py-1 px-3 focus:outline-none text-sm  text-white ${
                  isSelected ? "bg-red-500" : " bg-lime-600"
                }`}
                onClick={onClick}
              >
                {isSelected ? "Remove" : "Add"}
              </button>
            )}
          </h3>
          <h4 className="font-medium">{description}</h4>
        </div>
        <span>${price}</span>
        {isCustomer && options && options?.length !== 0 && (
          <div>
            <h5 className="mt-8 mb-3 font-medium">Dish Options:</h5>
            <div className="grid gap-2 justify-start ">{dishOptions}</div>
          </div>
        )}
      </div>

      <div className="w-32 h-32 ml-6 flex-shrink-0">
        <img
          className="w-full h-full object-cover rounded"
          src={photo ?? ""}
          alt={name}
        />
      </div>
    </div>
  );
};

// import React, { ReactNode } from "react";

// interface IDishProps {
//   id?: number;
//   description: string;
//   name: string;
//   price: number;
//   isCustomer?: boolean;
//   orderStarted?: boolean;
//   isSelected?: boolean;
//   options?: any;
//   children?: ReactNode;
//   photo: string | null | undefined;
//   addItemToOrder?: (dishId: number) => void;
//   removeFromOrder?: (dishId: number) => void;
// }

// export const Dish: React.FC<IDishProps> = ({
//   id = 0,
//   description,
//   name,
//   price,
//   isCustomer,
//   orderStarted = false,
//   options,
//   isSelected,
//   photo,
//   addItemToOrder,
//   removeFromOrder,
//   children: dishOptions,
// }) => {
//   const onClick = () => {
//     // if (orderStarted) {
//     //   if (!isSelected && addItemToOrder) {
//     //     return addItemToOrder(id);
//     //   }
//     //   if (isSelected && removeFromOrder) {
//     //     return removeFromOrder(id);
//     //   }
//     // }
//     if (!isCustomer || !orderStarted) return; // Disable function if not customer

//     if (!isSelected && addItemToOrder) {
//       return addItemToOrder(id);
//     }
//     if (isSelected && removeFromOrder) {
//       return removeFromOrder(id);
//     }
//   };
//   return (
//     <div
//       className={`flex justify-between items-center px-8 py-4 border-2 border-gray-300 cursor-pointer  transition-all ${
//         isSelected ? "text-white bg-gray-800" : " text-black"
//       }`}
//     >
//       <div>
//         <div className="mb-5">
//           <h3 className="text-lg font-medium flex items-center ">
//             {name}{" "}
//             {orderStarted && (
//               <button
//                 className={`ml-3 py-1 px-3 focus:outline-none text-sm  text-white ${
//                   isSelected ? "bg-red-500" : " bg-lime-600"
//                 }`}
//                 onClick={onClick}
//               >
//                 {isSelected ? "Remove" : "Add"}
//               </button>
//             )}
//           </h3>
//           <h4 className="font-medium">{description}</h4>
//         </div>
//         <span>${price}</span>
//         {isCustomer && options && options?.length !== 0 && (
//           <div>
//             <h5 className="mt-8 mb-3 font-medium">Dish Options:</h5>
//             <div className="grid gap-2 justify-start ">{dishOptions}</div>
//           </div>
//         )}
//       </div>

//       <div className="w-32 h-32 ml-6 flex-shrink-0">
//         <img
//           className="w-full h-full object-cover rounded"
//           src={photo ?? ""}
//           alt={name}
//         />
//       </div>
//     </div>
//   );
// };

// // import React, { ReactNode } from "react";

// // interface IDishProps {
// //   id?: number;
// //   restaurantId: number;
// //   description: string;
// //   name: string;
// //   price: number;
// //   photo: string | null | undefined;
// //   children?: ReactNode;
// //   isCustomer?: boolean;
// //   orderStarted?: boolean;
// //   isSelected?: boolean;
// //   options?: any;
// //   addItemToOrder?: (dishId: number) => void;
// //   removeFromOrder?: (dishId: number) => void;
// // }

// // export const Dish: React.FC<IDishProps> = ({
// //   id = 0,
// //   restaurantId,
// //   description,
// //   name,
// //   price,
// //   photo,
// //   options,
// //   isSelected,
// //   isCustomer = false,
// //   orderStarted = false,
// //   addItemToOrder,
// //   removeFromOrder,
// //   children: dishOptions,
// // }) => {
// //   const onClick = () => {
// //     if (orderStarted) {
// //       if (!isSelected && addItemToOrder) {
// //         return addItemToOrder(id);
// //       }
// //       if (isSelected && removeFromOrder) {
// //         return removeFromOrder(id);
// //       }
// //     }
// //   };
// //   return (
// //     <div
// //       className={`px-8 py-4 border cursor-pointer  transition-all flex justify-between items-center ${
// //         isSelected ? "border-gray-800" : "hover:border-gray-800"
// //       }`}
// //     >
// //       <div>
// //         <div className="mb-5">
// //           <h3 className="text-lg font-medium flex items-center ">
// //             {name}{" "}
// //             {orderStarted && (
// //               <button
// //                 className={`ml-3 py-1 px-3 focus:outline-none text-sm text-white ${
// //                   isSelected ? "bg-red-500" : "bg-lime-600"
// //                 }`}
// //                 onClick={onClick}
// //               >
// //                 {isSelected ? "Remove" : "Add"}
// //               </button>
// //             )}
// //           </h3>
// //           <h4 className="font-medium">{description}</h4>
// //         </div>
// //         <span>${price}</span>
// //         {isCustomer && options && options?.length !== 0 && (
// //           <div>
// //             <h5 className="mt-8 mb-3 font-medium">Dish Options:</h5>
// //             <div className="grid gap-2 justify-start">{dishOptions}</div>
// //           </div>
// //         )}
// //       </div>
// //       <div className="w-32 h-32 ml-6 flex-shrink-0">
// //         <img
// //           className="w-full h-full object-cover rounded"
// //           src={photo ?? ""}
// //           alt={name}
// //         />
// //       </div>
// //     </div>
// //   );
// // };
